import {GetExcludeCAB} from "@/services/transactions.service";

const getActiveGlCodeList = async ()=>{
    let { data } = await GetExcludeCAB();
    const glCodeList=  buildCategory(data);
    return glCodeList 
}



const buildCategory = (data)=> {
    let accounts = data.chart.chart;
    let glCode=[]
    for (const account in accounts) {
        for (const sub in accounts[account].sub) {
            for (const supersub in accounts[account].sub[sub].sub) {
                const code = accounts[account].sub[sub].sub[supersub].gl_code
                if(code===undefined || code===null){
                    continue
                }
                glCode.push(code)
            }
            
        }
    }
  return glCode
}

export default getActiveGlCodeList