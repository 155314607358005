<template>
  <b-form-group
    :id="groupId"
    :label="label"
    :label-for="id"
    label-class="form-label"
    :description="description"
    :invalid-feedback="invalidFeedback"
    :state="state"
    :class="[
      localValue ? (localValue.toString().length > 0 ? 'is-filled is-focus' : '') : '',
      localValue && counter ? (localValue.toString().length > counter ? 'is-over-count' : '') : '',
      isFocused ? 'is-focus' : '',
      whiteBG ? 'white-label-bg' : '',
      isDisabled ? 'is-disabled' : '',
      counter ? 'with-counter' : '',
    ]"
  >
    <b-form-textarea
      v-if="isTextArea"
      :id="id"
      :type="type"
      :required="isRequired"
      :placeholder="isFocused ? placeholder : ''"
      :state="state"
      rows="6"
      max-rows="9"
      :ref="id"
      :trim="trim"
      :form="form"
      :disabled="isDisabled"
      :value="value"
      @input="(e) => updateInput(e)"
      @blur="(e) => localChangeFn(e)"
      @focus="(e) => onFocus(e)"
    ></b-form-textarea>
    <b-form-input
      v-else
      :id="id"
      :type="type"
      :required="isRequired"
      :placeholder="isFocused ? placeholder : ''"
      :state="state"
      :ref="id"
      :trim="trim"
      :form="form"
      :value="value"
      :disabled="isDisabled"
      @input="(e) => updateInput(e)"
      @blur="(e) => localChangeFn(e)"
      @focus="(e) => onFocus(e)"
    ></b-form-input>
    <span v-if="counter" class="input-counter">{{
      `${localValue ? localValue.toString().length : 0}/${counter}`
    }}</span>
  </b-form-group>
</template>

<script>
  /* eslint-disable no-console */
  export default {
    name: "FormInput",
    data() {
      return {
        localValue: "",
        isFocused: false,
      };
    },
    props: {
      id: String,
      groupId: String,
      type: String,
      label: String,
      description: {
        default: "",
        type: String,
      },
      isRequired: Boolean,
      trim: {
        default: false,
        type: Boolean,
      },
      placeholder: String,
      invalidFeedback: {
        default: "",
        type: String,
      },
      isTextArea: {
        default: false,
        type: Boolean,
      },
      isDisabled: {
        default: false,
        type: Boolean,
      },
      state: {
        default: null,
        type: Boolean,
      },
      form: {
        default: "",
        type: String,
      },
      whiteBG: {
        default: true,
        type: Boolean,
      },
      value: {
        default: null,
      },
      counter: {
        default: false,
        type: [Number, Boolean],
      },
    },
    watch: {
      value: {
        immediate: true,
        handler(val) {
          this.localValue = val;
        },
      },
    },
    mounted() {
      this.localValue = this.value;
    },
    methods: {
      updateInput(val) {
        this.localValue = val;
        this.$emit("input", val);
      },
      localChangeFn(val) {
        this.isFocused = false;
        this.$emit("blurLocal", val.target.value);
      },
      onFocus() {
        this.isFocused = true;
        //   console.log("focused ", this.id, " in ", this.groupId);
      },
    },
  };
</script>
<!-- "unscoped" attribute for global CSS -->
<style scoped>
  /* == FORM GROUP STYLING == */
  /* customized form control */
  .form-group {
    position: relative;
  }
  /* placeholder text color */
  .form-group >>> input.form-control::placeholder,
  .form-group >>> textarea.form-control::placeholder {
    color: var(--prime-gray);
  }
  /* == FORM INPUT STYLING == */
  /* form input control styling (default state) */
  .form-group >>> input.form-control,
  .form-group >>> textarea.form-control {
    background-color: transparent;
    padding: 0.175rem 0.75rem;
    z-index: 5;
    border-radius: 5px;
    min-height: 40px;
    /* min-height: 25px; */
    border-color: var(--gray-550);
    /* type */
    -moz-appearance: textfield;
  }
  .form-group >>> textarea.form-control {
    overflow-y: auto !important;
  }
  /* form input control error state styling (valid state) */
  .form-group.is-valid >>> input.form-control,
  .form-group.is-valid >>> textarea.form-control {
    border-color: var(--success);
  }
  /* form input control error state styling (invalid state) */
  .form-group.is-invalid >>> input.form-control,
  .form-group.is-invalid >>> textarea.form-control {
    border-color: var(--danger);
  }
  /* form input control focused styling (focus state) */
  .form-group.is-focus >>> input.form-control,
  .form-group.is-focus >>> textarea.form-control {
    z-index: 0;
  }
  .form-group >>> input.form-control:focus,
  .form-group >>> textarea.form-control:focus {
    box-shadow: none;
    border-color: #0B9B7D;
  }
  /* form input control focused error state styling (focus/valid state) */
  .form-group.is-focus.is-valid >>> input.form-control,
  .form-group.is-focus.is-valid >>> textarea.form-control {
    border-color: var(--success);
  }
  /* form input control focused error state styling (focus/invalid state) */
  .form-group.is-focus.is-invalid >>> input.form-control,
  .form-group.is-focus.is-invalid >>> textarea.form-control {
    border-color: var(--danger);
  }
  /* form input control filled styling (filled state) */
  .form-group.is-filled >>> input.form-control,
  .form-group.is-filled >>> textarea.form-control {
    border-color: #0B9B7D;
  }
  /* form input control filled error state styling (filled/valid state) */
  .form-group.is-filled.is-valid >>> input.form-control,
  .form-group.is-filled.is-valid >>> textarea.form-control {
    border-color: var(--success);
  }
  /* form input control filled error state styling (filled/invalid state) */
  .form-group.is-filled.is-invalid >>> input.form-control,
  .form-group.is-filled.is-invalid >>> textarea.form-control {
    border-color: var(--danger);
  }
  /* form input control disabled state styling (disabled state) */
  .form-group.is-disabled >>> input.form-control,
  .form-group.is-disabled >>> textarea.form-control,
  .form-group:disabled >>> input.form-control,
  .form-group:disabled >>> textarea.form-control {
    color: var(--prime-gray);
    border-color: var(--ghost-white);
  }
  /* == LABEL STYLING == */
  /* form label (default state) */
  .form-group >>> label.form-label {
    position: absolute;
    color: var(--prime-gray);
    font-size: 1rem;
    width: auto;
    top: 8px;
    left: 13px;
    z-index: 1;
    cursor: text;
    white-space: nowrap;
    word-wrap: normal;
    text-overflow: clip;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    transition: top 100ms linear 0s, left 100ms linear 0s, color 100ms linear 0s,
      font-size 300ms cubic-bezier(0.2, 0, 0, 1) 0s, opacity 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
  }
  /* form label filled styling (filled state) */
  .form-group.is-filled >>> label.form-label {
    opacity: 0;
  }
  /* form label focused styling (focus state) */
  .form-group.is-focus >>> label.form-label {
    font-size: 0.8rem;
    color: #0B9B7D;
    top: -9px;
    left: 10px;
    opacity: 1;
    z-index: 2;
  }
  /* form label focused error state styling (focus/valid state) */
  .form-group.is-focus.is-valid >>> label.form-label {
    color: var(--success);
  }
  /* form label focused error state styling (focus/invalid state) */
  .form-group.is-focus.is-invalid >>> label.form-label {
    color: var(--danger);
  }
  /* form label disabled state styling (disabled state) */
  .form-group:disabled >>> label,
  .form-group.is-disabled >>> label {
    color: var(--ghost-white);
  }
  /* form label disabled state styling (focus/disabled state) */
  .form-group.is-focus:disabled >>> label,
  .form-group.is-focus.is-disabled >>> label.form-label {
    color: var(--ghost-white);
  }
  /* form label background (default state) */
  .form-group >>> label:before {
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--white);
    z-index: -1;
  }
  /* form label background focused styling (focus state) */
  .form-group.is-focus >>> label::before {
    width: 100%;
    height: 100%;
  }
  /* form label background disabled focused styling (disabled/focus state) */
  .form-group:disabled.is-focus >>> label::before,
  .form-group.is-disabled.is-focus >>> label::before {
    background-color: var(--white);
    height: 3px;
    top: 8px;
  }
  /* white label bg */
  .form-group.white-label-bg >>> label:before {
    background-color: var(--white);
  }
  /* == CHARACTER COUNTER STYLING == */
  /* input character counter */
  .form-group .input-counter {
    position: absolute;
    top: 13px;
    right: 13px;
  }
  .form-group.with-counter >>> input.form-control,
  .form-group.with-counter >>> textarea.form-control {
    padding-right: 100px;
  }
  /* input character count (if it gets over it) */
  .form-group.with-counter.is-over-count >>> input.form-control,
  .form-group.with-counter.is-over-count >>> textarea.form-control {
    border-color: var(--danger);
  }
  .form-group.with-counter.is-over-count >>> .input-counter {
    color: var(--danger);
  }
  .form-group.with-counter.is-over-count >>> label.form-label {
    color: var(--danger);
  }
  /* input character counter on error state styling (valid/invalid state) */
  .form-group.is-valid >>> .input-counter,
  .form-group.is-invalid >>> .input-counter {
    right: 36px;
  }
  /* == CUSTOM BROWSER SPECIFIC STYLING == */
  /* input type number fix */
  /* Chrome, Safari, Edge, Opera */
  .form-group >>> input.form-control::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  .form-group >>> input.form-control[type="number"] {
    -moz-appearance: textfield;
  }
</style>
