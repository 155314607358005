import axios from "axios";

export const GetExcludeCAB = async () => {
  try {
    return await axios.get("/chart_of_account/excludeCAB");
  } catch (error) {
    return error;
  }
};

export const GetAllChartOfAccounts = async () => {
  try {
    return await axios.get("/chart_of_account");
  } catch (error) {
    return error;
  }
};

export const GetCashAndBank = async () => {
  try {
    return await axios.get("/chart_of_account/cash_and_bank");
  } catch (error) {
    return error;
  }
};

export const GetAllFilters = async ({
  limit = 10,
  text,
  start_date,
  end_date,
  dateRange,
  type,
  account,
  category,
  sort = undefined,
  order,
  page,
}) => {
  try {
    return await axios.get(`/transactions`, {
      params: {
        limit,
        text,
        start_date,
        end_date,
        dateRange,
        type,
        account,
        category,
        sort,
        order,
        page,
      },
    });
  } catch (error) {
    return error;
  }
};

export const AddChartOfAccount = async (payload) => {
  try {
    return await axios.post(`/chart_of_account/create`, payload);
  } catch (error) {
    throw new Error(error.response.data.msg);
  }
};
export const GetChartOfAccount = async (payload, id) => {
  try {
    return await axios.get(`/chart_of_account/${id}`, payload);
  } catch (error) {
    return error;
  }
};
export const UpdateChartOfAccount = async (payload, id) => {
  try {
    return await axios.put(`/chart_of_account/${id}`, payload);
  } catch (error) {
    return error;
  }
};

export const GetAllTransactions = async (page = 1, limit = 10) => {
  try {
    return await axios.get(`/transactions`, {
      params: {
        limit,
        page,
        sort: "date",
      },
    });
  } catch (error) {
    return error;
  }
};

export const GetSingleTransaction = async (payload) => {
  try {
    return await axios.get(`/transactions?id=${payload}`);
  } catch (error) {
    return error;
  }
};

export const AddTransaction = async (payload) => {
  try {
    return await axios.post(`/transactions/create`, payload);
  } catch (error) {
    return error;
  }
};

export const UpdateTransaction = async (payload) => {
  try {
    return await axios.put(`/transactions/update/${payload.id}`, payload);
  } catch (error) {
    return error;
  }
};
export const MarkAsReviewed = async (payload) => {
  try {
    return await axios.put(
      `/transactions/markAsReviewed/${payload.id}`,
      payload
    );
  } catch (error) {
    return error;
  }
};

export const DeleteTransactions = async (payload) => {
  try {
    return await axios.delete(`/transactions/bulkdelete`, {
      data: { ids: payload },
    });
  } catch (error) {
    return error;
  }
};

export const UploadBankStatement = async (payload) => {
  try {
    return await axios.post(`/transactions/bankstatement`, payload);
  } catch (error) {
    return error;
  }
};

export const UploadMatchBankStatement = async (payload) => {
  try {
    return await axios.post(`/transactions/bankstatement/match`, payload);
  } catch (error) {
    return error.response;
  }
};

export const ValidateDates = async (payload) => {
  try {
    return await axios.post(
      `/transactions/bankstatement/date_format_validation`,
      payload
    );
  } catch (error) {
    return error;
  }
};

export const SearchTransactions = async (params) => {
  try {
    return await axios.get(`/transactions/search`, {
      params,
    });
  } catch (error) {
    return error;
  }
};

export const GetAccountTotals = async () => {
  try {
    return await axios.get(`/analytics/accountTotals`);
  } catch (error) {
    return error;
  }
};
